import icon from '@mui/icons-material/Abc';
import list from './WatchPhraseList';
import create from './WatchPhraseCreate';
import edit from './WatchPhraseEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon: icon,
  list: list,
  create: create,
  edit: edit,
  options: { label: 'Watch Phrases' },
};
