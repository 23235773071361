import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import inMemoryJWT from './inMemoryJWT';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const fetchJson = url => {
  const token = inMemoryJWT.getToken();
  const options = {
    headers: new Headers({ Accept: 'application/json' }),
  };

  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  }

  inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/auth/refresh`);

  return inMemoryJWT.getRefreshedToken().then(gotFreshToken => {
    if (gotFreshToken) {
      options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
    }
    return fetchUtils.fetchJson(url, options);
  });
};

const authHeaders = () => {
  const token = inMemoryJWT.getToken();
  const headers = new Headers({ Accept: 'application/json' });

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  return { headers };
};
const httpClient = fetchJson;

const DataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    const { headers, json } = await httpClient(url);
    return {
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    };
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { json } = await httpClient(url);
    return { data: json };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    const { headers, json } = await httpClient(url);
    return {
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    };
  },

  create: async (resource, params) => {
    const methodOptions = {
      method: 'POST',
      body: JSON.stringify(params.data),
    };

    const { json } = await fetchUtils.fetchJson(`${apiUrl}/${resource}`, { ...methodOptions, ...authHeaders() });
    return { data: json.data };
  },

  update: async (resource, params) => {
    const methodOptions = {
      method: 'PUT',
      body: JSON.stringify(params.data),
    };

    const { json } = await fetchUtils.fetchJson(`${apiUrl}/${resource}/${params.id}`, { ...methodOptions, ...authHeaders() });
    return { data: json.data };
  },

  updateMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };

    const methodOptions = {
      method: 'PUT',
      body: JSON.stringify(params.data),
    };

    const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, { ...methodOptions, ...authHeaders() });
    return { data: json };
  },

  delete: async (resource, params) => {
    const methodOptions = {
      method: 'DELETE',
    };

    const { json } = await fetchUtils.fetchJson(`${apiUrl}/${resource}/${params.id}`, { ...methodOptions, ...authHeaders() });
    return { data: json.data };
  },

  deleteMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const { json } = await httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    });
    return { data: json };
  },
};

export default DataProvider;
