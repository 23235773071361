import React from 'react';
import { List, Datagrid, SearchInput, Pagination, TextField, BooleanField, FunctionField, TopToolbar, CreateButton } from 'react-admin';
import { Box } from '@mui/material';

const filters = [<SearchInput source="q" alwaysOn />];

const ListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <CreateButton label="New Watch Phrase" size="large" />
    </TopToolbar>
  </Box>
);

const WatchPhraseList = props => {
  return (
    <List {...props} filters={filters} title="Watch Phrases" actions={<ListActions />} perPage={25} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 250, 500]} />}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        {/* <NumberField source="id" /> */}
        <TextField source="title" label="Description" />
        <FunctionField
          sx={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }}
          label="Phrase(s)"
          render={record => <TextField record={{ phrase: Array.isArray(record.phrases) ? record.phrases.join(', ') : record.phrases }} source="phrase" />}
        />
        <BooleanField source="enabled" label="Enabled" />
      </Datagrid>
    </List>
  );
};

export default WatchPhraseList;
