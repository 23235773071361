import React from 'react';
import { Admin, Resource, CustomRoutes, memoryStore } from 'react-admin';
import { Route } from 'react-router-dom';
// import restProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';
import dataProvider from './dataProvider';

import Dashboard from './components/dashboard';
import MyLayout from './layout/Layout';
import Login from './layout/Login';
import theme from './layout/theme';

// Load Resources
import WatchPhrases from './components/watchPhrases';
import discordRegistrations from './components/discordRegistrations';
import DiscordChannels from './components/discordChannels';

function App() {
  return (
    <Admin theme={theme} layout={MyLayout} authProvider={authProvider} dataProvider={dataProvider} loginPage={Login} store={memoryStore()} disableTelemetry requireAuth>
      <CustomRoutes>
        <Route path="/" element={<Dashboard />} />
      </CustomRoutes>
      <Resource name="v1/watchphrases" {...WatchPhrases} />
      <Resource name="v1/discord/channels" {...DiscordChannels} />
      <Resource name="v1/discord/registrations" {...discordRegistrations} />
    </Admin>
  );
}

export default App;
