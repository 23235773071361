import * as React from 'react';

import { Edit, BooleanInput, TextInput, ArrayInput, ReferenceArrayInput, SimpleFormIterator, useNotify, useRedirect, useRefresh, TabbedForm, AutocompleteArrayInput } from 'react-admin';
import { Box, Card, Grid, Typography } from '@mui/material';

const WatchPhraseEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('Record updated successfully', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    redirect('/v1/watchphrases');
    refresh();
  };

  return (
    <Edit component="div" mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <EditForm />
    </Edit>
  );
};

const validateWatchPhrase = values => {
  const errors = {};

  if (!values.phrases || !values.phrases.length) {
    errors.phrases = 'At least one trigger is required.';
  }

  if (!values.responses || !values.responses.length) {
    errors.responses = 'At least one response is required.';
  }

  return errors;
};

const EditForm = () => {
  return (
    <Box maxWidth="50em">
      <Card>
        <TabbedForm validate={validateWatchPhrase}>
          <TabbedForm.Tab label="General">
            <Grid container spacing={0}>
              <Grid item xs={10}>
                <Typography variant="h6" gutterBottom>
                  General Details
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <BooleanInput source="enabled" label="Enabled" variant="standard" options={{ defaultValue: true }} />
              </Grid>

              <Grid item xs={12}>
                <TextInput source="title" label="Description" variant="standard" fullWidth required />
              </Grid>

              <Grid item xs={12}>
                <ReferenceArrayInput source="channels" reference="v1/discord/channels" sort={{ field: 'name', order: 'ASC' }}>
                  <AutocompleteArrayInput variant="standard" label="Limit to Channels" optionText="name" optionValue="id" fullWidth />
                </ReferenceArrayInput>
              </Grid>
            </Grid>
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Triggers">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <ArrayInput source="phrases" label="" fullWidth>
                  <SimpleFormIterator
                    sx={{
                      '& .RaSimpleFormIterator-line': {
                        borderBottomWidth: 0,
                      },
                    }}
                    inline
                  >
                    <TextInput label="Phrase" helperText={false} variant="standard" required />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Responses">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <ArrayInput source="responses" label="" fullWidth>
                  <SimpleFormIterator
                    fullWidth
                    sx={{
                      '& .RaSimpleFormIterator-line': {
                        borderBottomWidth: 0,
                      },
                    }}
                  >
                    <TextInput label="Response" helperText={false} variant="standard" required multiline fullWidth />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </TabbedForm.Tab>
        </TabbedForm>
      </Card>
    </Box>
  );
};
export default WatchPhraseEdit;
