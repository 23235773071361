import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

// const filters = [<SearchInput source="title" alwaysOn />];

const DiscordChannelList = props => {
  return (
    <List {...props} title="Discord Channels" perPage={25}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" label="Identifier" />
        <TextField source="name" label="Full Name" />
      </Datagrid>
    </List>
  );
};

export default DiscordChannelList;
