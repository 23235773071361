import React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';

// const filters = [<SearchInput source="title" alwaysOn />];

const DiscordRegistrationList = props => {
  return (
    // <List {...props} filters={filters} title="Discord Registrations">
    <List {...props} title="Discord Registrations" perPage={25}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        {/* <TextField source="id" label="Identifier" /> */}
        <TextField source="name" label="Full Name" />
        <TextField source="email" label="Email Address" />
        <BooleanField source="processed" label="Processed" />
      </Datagrid>
    </List>
  );
};

export default DiscordRegistrationList;
