import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Button, Create, SimpleForm, BooleanInput, TextInput, ArrayInput, SimpleFormIterator, useNotify, useRefresh, useRedirect } from 'react-admin';
import { Box, Card, Grid, Typography } from '@mui/material';

const WatchPhraseCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('Record created successfully', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    redirect('/v1/watchphrases');
    refresh();
  };

  return (
    <Create component="div" mutationMode="pessimistic" mutationOptions={{ onSuccess }} title="Create Watch Phrase">
      <CreateForm />
    </Create>
  );
};

const validateWatchPhrase = values => {
  const errors = {};

  if (!values.phrases || !values.phrases.length) {
    errors.phrases = 'At least one trigger is required.';
  }

  if (!values.responses || !values.responses.length) {
    errors.responses = 'At least one response is required.';
  }

  return errors;
};

const CreateForm = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="50em">
      <Card>
        <SimpleForm validate={validateWatchPhrase}>
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <Typography variant="h6" gutterBottom>
                Watch Phrase Details
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button size="medium" alignIcon="left" label="Back" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </Button>
            </Grid>

            <Grid item xs={12}>
              <TextInput source="title" label="Description" fullWidth required />
            </Grid>

            <Grid item xs={12}>
              <BooleanInput source="enabled" label="Enabled" defaultValue={true} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h7" gutterBottom>
                Triggers
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ArrayInput source="phrases" label="" fullWidth>
                <SimpleFormIterator
                  sx={{
                    '& .RaSimpleFormIterator-line': {
                      borderBottomWidth: 0,
                    },
                  }}
                  inline
                >
                  <TextInput label="Phrase" helperText={false} required />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h7" gutterBottom>
                Responses
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ArrayInput source="responses" label="" fullWidth>
                <SimpleFormIterator
                  fullWidth
                  sx={{
                    '& .RaSimpleFormIterator-line': {
                      borderBottomWidth: 0,
                    },
                  }}
                >
                  <TextInput label="Response" helperText={false} required multiline fullWidth />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </SimpleForm>
      </Card>
    </Box>
  );
};
export default WatchPhraseCreate;
