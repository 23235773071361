import jwt from 'jwt-decode';
import inMemoryJWT from './inMemoryJWT';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const authProvider = {
  // authentication

  login: ({ code, state }) => {
    if (!code) {
      setTimeout(() => {
        window.location.replace(`${backendURL}/v1/auth/login`);
        return;
      }, 150);
    }

    inMemoryJWT.setRefreshTokenEndpoint(`${backendURL}/v1/auth/refresh`);

    // Exchange oauth code for token
    const request = new Request(`${backendURL}/v1/auth/exchange?code=${code}`, { method: 'GET', headers: new Headers({ 'Content-Type': 'application/json' }), credentials: 'include' });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(({ accessToken, accessTokenLifetime }) => {
        let tokenSet = inMemoryJWT.setToken(accessToken, accessTokenLifetime);
        return tokenSet;
      })
      .catch(error => {
        // console.log(error);
      });
  },

  checkAuth: () => {
    if (inMemoryJWT.getToken()) {
      return inMemoryJWT.waitForTokenRefresh().then(async () => {
        return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject({ message: false });
      });
    } else {
      return inMemoryJWT.getRefreshedToken().then(() => {
        return inMemoryJWT.waitForTokenRefresh().then(async () => {
          return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject({ message: false });
        });
      });
    }
  },

  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      inMemoryJWT.ereaseToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  logout: async () => {
    const request = new Request(`${backendURL}/v1/auth/logout`, { method: 'GET', headers: new Headers({ 'Content-Type': 'application/json' }), credentials: 'include' });
    inMemoryJWT.ereaseToken();
    return fetch(request).then(() => '/login');
  },

  getIdentity: () => {
    const accessToken = inMemoryJWT.getToken();

    if (accessToken) {
      const decodedToken = jwt(accessToken);
      return { id: decodedToken.userid, fullName: decodedToken.name };
    } else {
      return {};
    }
  },

  // authorization
  getPermissions: () => {
    return inMemoryJWT.waitForTokenRefresh().then(() => {
      let roles = jwt(inMemoryJWT.getToken()).roles;
      return Promise.resolve(roles);
    });
  },
};
export default authProvider;
